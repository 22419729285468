import * as tslib_1 from "tslib";
import { MatDialogRef } from '@angular/material';
import { Template } from '../../shared/models/template.model';
import { BasicInfoFormMode } from '../template-basic-info-form/template-basic-info-form.component';
export class CreateEditTemplateDialogComponent {
    constructor(dialogRef, data, _templateService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this._templateService = _templateService;
        this.showInstituionEntityTypesSelect = false;
        this.init();
    }
    createOrEdit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.busy = true;
            try {
                let template;
                if (this.template._id) {
                    const { title, description, type, therapyId, therapyTypeId, dmsFieldmapping, dmsDocumentType, dmsAccessList, dmsModule, payer, templateTag, entityTypes, groupingText, sendEmailOnComplete, disableReport, sendToOcr, } = this.template;
                    template = yield this._templateService.patch(this.template._id, {
                        title: title || null,
                        description: description || null,
                        type: type || null,
                        therapyId: therapyId || null,
                        therapyTypeId: therapyTypeId || null,
                        dmsFieldmapping: dmsFieldmapping || null,
                        dmsDocumentType: dmsDocumentType || null,
                        dmsAccessList: dmsAccessList || null,
                        dmsModule: dmsModule || null,
                        payer: payer || null,
                        templateTag: templateTag || null,
                        entityTypes: entityTypes || null,
                        groupingText: groupingText || null,
                        sendEmailOnComplete: sendEmailOnComplete || null,
                        disableReport: disableReport || null,
                        sendToOcr: sendToOcr || null,
                    });
                }
                else {
                    this.template.draft = true;
                    template = yield this._templateService.create(this.template);
                }
                this.dialogRef.close(template);
            }
            catch (e) {
                this.error = e.message;
            }
            finally {
                this.busy = false;
            }
        });
    }
    init() {
        if (this.data.template) {
            this.template = this.data.template;
            this.templateEditorMode = this.getFormMode(this.template.entityTypes[0]);
        }
        else {
            this.template = new Template();
            if (this.data.entityTypes.length === 1) {
                this.templateEditorMode = this.getFormMode(this.data.entityTypes[0]);
                this.template.entityTypes = this.data.entityTypes;
            }
            else {
                this.templateEditorMode = BasicInfoFormMode.institution;
            }
        }
    }
    getFormMode(entityType) {
        switch (entityType) {
            case 'patient':
                return BasicInfoFormMode.patient;
            case 'integratedCare':
            case 'singleOrder':
            case 'directOrder':
            case 'returnDelivery':
                return BasicInfoFormMode.careDocuments;
            case 'crossTherapy':
                return BasicInfoFormMode.crossTherapy;
            default:
                return BasicInfoFormMode.institution;
        }
    }
}
