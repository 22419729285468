import * as tslib_1 from "tslib";
import { XSDGenerator } from '@pia/pia.swodoc.document-generator';
import { ElementRef, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { MetadataGenerator } from 'src/app/template/template-detail/metadata-generator';
import { environment } from 'src/environments/environment';
export class TemplateDetailComponent {
    constructor(fullscreenService, sanitizer, _auth, _templateService, _reportService) {
        this.fullscreenService = fullscreenService;
        this.sanitizer = sanitizer;
        this._auth = _auth;
        this._templateService = _templateService;
        this._reportService = _reportService;
        this.updated = new EventEmitter();
        this.templateUrl = 'about:blank';
        this._ngUnsusbscribe = new Subscription();
    }
    ngOnChanges() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.template) {
                return;
            }
            const initTab = (index) => {
                switch (index) {
                    case 0:
                        this.initIframe();
                        break;
                    case 2:
                        setTimeout(() => {
                            this.reportComponent.renderDesigner();
                        }, 1);
                        break;
                }
            };
            this._ngUnsusbscribe = new Subscription();
            this._ngUnsusbscribe.add(this.tabs.selectedIndexChange.subscribe((index) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.save();
                initTab(index);
            })));
            this._ngUnsusbscribe.add(this.fullscreenService.fullscreen.subscribe((fullscreen) => (this.fullscreen = fullscreen)));
            initTab(this.tabs.selectedIndex);
        });
    }
    initIframe() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.templateUrl = 'about:blank';
            const token = yield this._auth.getToken();
            this.templateUrl = `${environment.templateBuilderUrl}/#/template/${this.template._id}`;
            if (this._iframe && this._iframe.nativeElement && this._iframe.nativeElement.contentWindow) {
                const listener = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this._iframe.nativeElement.contentWindow.postMessage({ action: 'init', template: this.template, token }, '*');
                    this._iframe.nativeElement.removeEventListener('load', listener);
                });
                this._iframe.nativeElement.addEventListener('load', listener);
            }
        });
    }
    ngOnDestroy() {
        this._ngUnsusbscribe.unsubscribe();
        this.templateUrl = 'about:blank';
    }
    toggleFullscreen() {
        this.fullscreenService.toggle();
    }
    close() {
        this.fullscreenService.set(false);
        this.sidenav.close();
        this.tabs.selectedIndex = 0;
        this.ngOnDestroy();
    }
    save() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.reportComponent.save(this.tabs.selectedIndex);
            if (this._iframe && this._iframe.nativeElement && this._iframe.nativeElement.contentWindow) {
                this._iframe.nativeElement.contentWindow.postMessage({ action: 'saving' }, '*');
                yield new Promise((resolve) => {
                    const corsMessageHander = (event) => {
                        if (~event.origin.indexOf(environment.templateBuilderUrl)) {
                            if (event.data && event.data.action && event.data.action === 'save' && event.data.template) {
                                this.template = event.data.template;
                                resolve();
                                window.removeEventListener('message', corsMessageHander);
                            }
                        }
                        else {
                            return;
                        }
                    };
                    window.addEventListener('message', corsMessageHander);
                });
            }
            const templateToSave = Object.assign({}, this.template);
            delete templateToSave.match;
            delete templateToSave.report;
            delete templateToSave.selected;
            this._templateService.patch(templateToSave._id, templateToSave);
            yield this.matchComponent.save();
            this.updated.emit(this.template._id);
        });
    }
    exportXSD() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const xsdGenerator = new XSDGenerator();
            try {
                const xsd = yield xsdGenerator.generate(this.template, new MetadataGenerator());
                saveAs(new Blob([xsd], { type: 'application/xml' }), `${this.template.title}.xsd`);
            }
            catch (error) {
                console.error('error exporting xsd', error);
            }
        });
    }
}
